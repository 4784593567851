import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';

const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

export default {
  computed: {
    ...mapState(['account']),
    ...mapGetters(['activeDomainsCount']),
    ...paymentGetters(['isOverrun', 'limitUsageInPercent', 'maxLimit', 'usedLimit']),
    getDomainHeader() {
      return this.account.limits.domains > 990 || this.account.type === 'sub'
        ? this.$t('headerDomainUnlimited')
        : `${this.addThousandSep(this.activeDomainsCount)} / ${this.addThousandSep(
            this.account.limits.domains,
          )}`;
    },
    getVisitorHeader() {
      if (!this.maxLimit) return this.addThousandSep(this.usedLimit);

      const transParams = { used: null, max: null };
      transParams.used = this.addThousandSep(this.usageStat);
      transParams.max = this.addThousandSep(this.maxLimit);
      return `${transParams.used} / ${transParams.max}`;
    },
    usageStat() {
      return !this.isOverrun && this.usedLimit > this.maxLimit ? this.maxLimit : this.usedLimit;
    },
    visitorProgressWidth() {
      return `${this.limitUsageInPercent}%`;
    },
  },
  methods: {
    addThousandSep(value) {
      return this.$options.filters.thousandSep(value);
    },
  },
};
