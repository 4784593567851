import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['account', 'accountType', 'partnerTypeService']),
    ...mapGetters([
      'isAffiliate',
      'userRole',
      'hasShopify',
      'hasShopRenter',
      'accountFeatures',
      'isRealTemplateAuthor',
      'hasCreatedExperiment',
    ]),
    dashboardRoute() {
      return this.partnerTypeService.isResellerPartnerType() || this.partnerTypeService.isCustom()
        ? 'agency_dashboard'
        : 'dashboard';
    },

    showAmbassadorDashboard() {
      return this.isAffiliate && this.userRole === 'owner';
    },

    items() {
      const agencyBase = [
        {
          title: this.$t('accounts'),
          route: 'agency_dashboard',
          icon: 'partner_dashboard',
        },
        {
          title: this.$t('templateManager.agencyTitle'),
          route: 'template_manager',
          icon: 'template-manager',
          beta: true,
        },
      ];

      const userBase = [
        ...(this.partnerTypeService.isAgencyPartnerType()
          ? [
              {
                title: this.$t('partnerDashboard'),
                route: 'agency_dashboard',
                icon: 'partner_dashboard',
              },
            ]
          : []),
        {
          title: this.$t('campaigns'),
          route: 'campaigns',
          icon: 'campaigns',
        },
        ...(this.hasCreatedExperiment
          ? [
              {
                title: this.$t('experiments.title'),
                route: 'experiments',
                icon: 'experiments',
              },
            ]
          : []),
        {
          title: this.$t('audience'),
          route: 'subscribers',
          icon: 'subscribers',
        },
        { title: this.$t('analytics'), route: 'analytics', icon: 'analytics' },
        {
          title: this.$t('templates'),
          route: 'templates',
          icon: 'templates',
        },
      ];

      if (this.partnerTypeService.isResellerPartnerType() || this.partnerTypeService.isCustom()) {
        if (this.showAmbassadorDashboard) {
          agencyBase.push({
            title: this.$t('ambassador'),
            route: 'ambassador_dashboard',
            icon: 'referral',
          });
        }
        return agencyBase;
      }
      if (
        this.isRealTemplateAuthor ||
        this.partnerTypeService.isResellerPartnerType() ||
        this.partnerTypeService.isCustom()
      ) {
        userBase.push({
          title: this.$t('templateManager.title'),
          route: 'template_manager',
          icon: 'template-manager',
          beta: true,
        });
      }
      if (this.showAmbassadorDashboard) {
        userBase.push({
          title: this.$t('ambassador'),
          route: 'ambassador_dashboard',
          icon: 'referral',
        });
      }

      return userBase;
    },
  },
};
