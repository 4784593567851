<template lang="pug">
.d-flex.flex-column.justify-content-center
  router-link.brand-side-nav-profile-popper-item(
    v-if="isPaymentEnabledForUser"
    :to="{ name: 'plan_settings' }"
    @click.native="$emit('close')"
  ) {{ $t('menu.upgrade') }}
  router-link.brand-side-nav-profile-popper-item(
    v-if="hasSharedAccounts"
    :to="{ name: 'switch_account' }"
  ) {{ $t('menu.switchAccount') }}
  router-link.brand-side-nav-profile-popper-item(
    :to="{ name: 'account_settings' }"
    @click.native="$emit('close')"
  ) {{ $t('menu.settings') }}
  router-link#inviteYourTeamProfileAction.brand-side-nav-profile-popper-item(
    v-if="!isImpersonate && !isSubUser"
    :to="{ name: 'account_sharing' }"
    @click.native="$emit('close')"
  ) {{ $t('menu.inviteYourTeam') }}
  router-link.brand-side-nav-profile-popper-item(
    v-if="getRegion !== 'Hungary' && !isSubUser && !isImpersonate && !partnerTypeService.isPartner() && !isAffiliate"
    :to="{ name: 'become_ambassador' }"
  ) {{ $t('forAgencies') }}
  a.brand-side-nav-profile-popper-item(
    v-if="!this.embedded"
    href="javascript:void(0)"
    @click="signOut()"
  ) {{ $t('menu.signOut') }}
</template>

<script>
  import { get as _get } from 'lodash-es';
  import { mapActions, mapState, mapGetters, createNamespacedHelpers } from 'vuex';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    computed: {
      ...mapState(['account', 'embedded', 'partnerTypeService']),
      ...paymentGetters(['isPaymentEnabledForUser']),
      ...mapGetters([
        'hasSharedAccounts',
        'isSubUser',
        'getRegion',
        'isImpersonate',
        'isAffiliate',
      ]),
      domains() {
        return _get(this.account, 'settings.domains');
      },
      notificationCount() {
        return this.notifications.length !== 0 ? this.notifications.length : 0;
      },
    },

    created() {
      this.fetchSharedAccounts();
    },

    methods: {
      ...mapActions(['fetchSharedAccounts', 'logout']),

      async signOut() {
        this.$emit('close');
        this.logout();
      },
    },
  };
</script>
