<template lang="pug">
.d-flex.flex-column.justify-content-center
  .brand-side-nav-profile-popper-content-body-right-item(v-if="isPaymentEnabledForAccount")
    .font-size-0--75.brand-side-nav-profile-popper-gray {{ $t('menu.myPlan') }}
    .font-size-0--75.brand-side-nav-profile-popper-black.ml-auto {{ getFormattedPackageName }}
  .brand-side-nav-profile-popper-content-body-right-item(
    v-if="isPaymentEnabledForAccount && currentPlan !== 'FREE'"
  )
    .font-size-0--75.brand-side-nav-profile-popper-gray {{ $t(cancelledPlan ? 'expiryDate' : nextPaymentKey) }}
    .font-size-0--75.brand-side-nav-profile-popper-black.ml-auto {{ formatDate(getNextPaymentDate) }}
  .brand-side-nav-profile-popper-content-body-right-item-progress(v-if="!isManagedReferral")
    .d-flex.justify-content-center
      .font-size-0--75.brand-side-nav-profile-popper-gray {{ isPageViewBasedPackage ? $t('pageViews') : $t('visitors') }}
      .ml-auto.font-size-0--75.brand-side-nav-profile-popper-black.text-right(
        data-testid="accountLimitUsage"
      ) {{ getVisitorHeader }}
    progress-bar(:width="visitorProgressWidth")
  .brand-side-nav-profile-popper-content-body-right-item-progress(
    v-if="!partnerTypeService.isResellerPartnerType() && !partnerTypeService.isCustom()"
  )
    .d-flex
      .font-size-0--75.brand-side-nav-profile-popper-gray {{ !isPageViewBasedPackage ? $t('activeDomainsCount') : $t('activeSites') }}
      .ml-auto.font-size-0--75.brand-side-nav-profile-popper-black {{ getDomainHeader }}
    .progress
      .progress-bar(
        role="progressbar"
        :style="{ width: account.type === 'sub' ? 0 : (activeDomainsCount / account.limits.domains) * 100 + '%', 'background-color': '#6D9AE7' }"
        :aria-valuenow="activeDomainsCount"
        aria-valuemin="0"
        :aria-valuemax="account.limits.domains"
      )
</template>

<script>
  import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';
  import ProgressBar from '@/components/Subscription/ProgressBar.vue';
  import dateFormat from '@/mixins/dateFormat';
  import flexiPay from '@/mixins/planSettings';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: { ProgressBar },
    mixins: [dateFormat, flexiPay],
    computed: {
      ...mapState(['account', 'embedded', 'flexiPayDetails', 'partnerTypeService']),
      ...mapGetters([
        'currentPlan',
        'cancelledPlan',
        'isPageViewBasedPackage',
        'isImpersonate',
        'activeDomainsCount',
        'isManagedReferral',
        'isPayingBy',
      ]),
      ...paymentGetters([
        'isPaymentEnabledForAccount',
        'getPlainPlanName',
        'isOverrunRunning',
        'isOverrun',
        'getNextPaymentDate',
        'maxLimit',
        'calculateCurrentOverRun',
        'getCurrentPeriodLanguageKey',
      ]),
      nextPaymentKey() {
        return this.$store.getters.isPaying ? 'nextPayment' : 'trialEnds';
      },
      getFormattedPackageName() {
        const plan = this.currentPlan;
        const planName = this.getPlainPlanName(plan);
        const formattedPlanName =
          planName.toLowerCase().charAt(0).toUpperCase() + planName.toLowerCase().slice(1);

        if (this.isPayingBy(plan) === false) {
          return formattedPlanName;
        }

        const periodKey = this.getCurrentPeriodLanguageKey;
        if (!periodKey) {
          return formattedPlanName;
        }

        const periodLanguageKey = `billingCycle.${periodKey}`;
        return `${formattedPlanName} (${this.$t(periodLanguageKey)})`;
      },
    },
  };
</script>
